import { createRouter, createWebHistory } from 'vue-router'
const Posts = () => import('../views/Posts.vue')
const About = () => import('../views/About.vue')
const Contact = () => import('../views/Contact.vue')
const PostRead = () => import('../views/PostRead.vue')
const Admin = () => import('../views/AdminPanel.vue')

const routes = [
  {
    path: '/',
    name: 'posts',
    component: Posts
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/post',
    name: 'post',
    component: PostRead
  },
  {
    path: '/television',
    name: 'television',
    component: Admin
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
